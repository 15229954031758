import React, { Component } from 'react';
import config from 'common/oldJavascripts/config.js';
import Box from 'common/oldJavascripts/components/Base/Box';
import Content from './I9Verifications/Content';
import FailureAlert from 'common/oldJavascripts/components/Shared/FailureAlert';
import Header from 'common/oldJavascripts/components/Base/Header';
import LayoutContent from 'common/oldJavascripts/components/Base/Layout/LayoutContent';
import Loader from 'common/components/Loader';
import Pagination from 'common/oldJavascripts/components/Base/Pagination';
import Search from 'common/oldJavascripts/components/Shared/Search';
import OfferRejectModal from 'common/oldJavascripts/components/Shared/OfferRejectModal';
import DocumentInteractionController from 'studio/oldJavascripts/components/Shared/DocumentInteractionController';
import ajax from 'common/utilities/ajax.js';
import processDocumentRequests from 'common/utilities/processDocumentRequests';
import Notification from 'studio/components/OfferPage/OfferDrafts/Notification';

import QueryRefresher from 'common/components/QueryRefresher';

// HoC
import { withStyles } from '@mui/styles';
import withApi from 'common/hoc/withApi';
import { compose } from 'redux';
import withPermissionProtection from 'common/hoc/withPermissionProtection';

import BoxItem from 'common/oldJavascripts/components/Base/Box/BoxItem';
import { Typography } from '@mui/material';
import RejectI9 from './RejectI9';

const styles = theme => ({
  loader: {
    backgroundColor: 'white',
    width: '100%',
    height: '200px',
  },
  recordsText: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.16px',
    color: '#8D8D8D',
  },
});

const PAST_DUE_LIMIT = 3;

/* eslint react/prop-types: "off" */

const REJECT_MODAL_CONTENT = {
  closeLink: 'Close',
  note_label: 'Note',
  note_placeholder:
    'Please add a short explanation for declining the approval of the offer.' +
    ' For example, "the allowance amount is over budget."',
  paragraph:
    'You have chosen to decline the selected offer.' +
    ' It is required that you give us a short explanation for this action.',
  submitBtn: 'Submit',
  title: 'Add a Note',
};

class I9Verifications extends Component {
  static queries = {
    i9Verifications: {
      info(params, related) {
        const routerParams = related['/router/params'];
        const queryParams = Object.assign({}, params, related['/router/query']);
        return {
          cursor: {
            direction: queryParams.direction,
            page: queryParams.page,
            per_page: queryParams.per_page || config.DEFAULT_PER_PAGE,
            q: queryParams.q,
            sort: queryParams.sort,
            t: queryParams.t || '',
          },
          id: `/v2/projects/${routerParams.projectId}/i9-verifications`,
        };
      },
    },

    project: {
      info(_, related) {
        const params = related['/router/params'];
        return {
          id: `/projects/${params.projectId}`,
        };
      },
    },

    me: {
      info() {
        return { id: '/me' };
      },
    },
  };
  static mutators = {
    offerReject: {
      info(params, related) {
        const routerParams = related['/router/params'];
        return {
          create: true,
          id: `/v2/projects/${routerParams.projectId}/approvals/reject`,
        };
      },
    },
  };

  state = {
    displaySigner: false,
    signingOfferId: null,
    errors: [],
    documentsSuccessfullySubmitted: [],
    rejectModalopen: false,
    message: null,
    snackOpen: false,
    documentId: {},
  };

  componentDidUpdate() {
    this.checkForProcessingOffers();
  }

  checkForProcessingOffers = () => {
    const {
      i9Verifications: {
        status: i9VerificationsStatus,
        data: { items: i9Verifications = [] } = {},
      } = {},
    } = this.props;

    if (i9VerificationsStatus === 'loading' || this.reloadOfferTimer) return;
    const i9VerificationsLoading = i9Verifications
      .filter(({ ready_to_sign }) => ready_to_sign === false)
      .map(({ _id }) => _id);

    this.reloadOfferTimer = setTimeout(() => {
      this.reloadProcessingOffers(i9VerificationsLoading);
    }, 2000);
  };

  reloadProcessingOffers = i9Verifications => {
    const { i9Verifications: { reloadSubResource } = {} } = this.props;
    i9Verifications.forEach(id => reloadSubResource(id));
    // Clean up Timer
    clearInterval(this.reloadOfferTimer);
    this.reloadOfferTimer = null;
  };

  destroyTracker = () => clearInterval(this.reloadOfferTimer);

  _renderFailure = () => {
    return <FailureAlert queryName="the I-9 verifications" />;
  };

  _renderPagination = () => {
    const data = this.props.i9Verifications.data;
    const {
      i9Verifications: {
        data: { items = [], total_items: totalI9Verifications = 0 },
        status,
      },
      classes,
    } = this.props;
    const recordsCount = items?.length || 0;
    if (data.items.length > 0) {
      return (
        <>
          <Pagination page={data.page} totalPages={data.total_pages} />
          {status === 'success' && (
            <Typography className={classes.recordsText}>
              Showing {recordsCount || 0} of {totalI9Verifications} offers
            </Typography>
          )}
        </>
      );
    }
  };

  openSigner = offerId => {
    this.setState({
      displaySigner: true,
      signingOfferId: offerId,
    });
  };

  handleOfferAccept = (docs, offerId, documents) => {
    const { documentsSuccessfullySubmitted } = this.state;

    const documentRequests = Object.keys(docs)
      .filter(d => !documentsSuccessfullySubmitted.includes(d))
      .map(d => {
        const fields = Object.keys(docs[d].fields).map(fieldId => ({
          id: docs[d].fields[fieldId].id,
          value: docs[d].fields[fieldId].value,
        }));
        const postUrl = `/v2/offers/${offerId}/documents/${d}`;
        const promise = ajax
          .post(postUrl, { fields }, { json: true })
          .then(() =>
            this.setState(({ documentsSuccessfullySubmitted }) => ({
              documentsSuccessfullySubmitted: documentsSuccessfullySubmitted.concat(
                d,
              ),
            })),
          );
        return { promise, documentId: d };
      });

    return processDocumentRequests(documentRequests).then(() => {
      const { i9Verifications } = this.props;
      i9Verifications.reload();
      this.setState({
        signingOfferId: null,
        displaySigner: false,
        errors: [],
      });
    });
  };

  handleSave = (docs, isAutosave) => {
    const {
      documentsSuccessfullySubmitted,
      signingOfferId: offerId,
    } = this.state;

    // Sometimes autosave is triggered after the offer is closed in which case
    // we should just ignore it.
    if (isAutosave && !offerId) return;

    const documentRequests = Object.keys(docs)
      .filter(d => docs[d].ready && !documentsSuccessfullySubmitted.includes(d))
      .map(d => {
        const fields = Object.keys(docs[d].fields).map(fieldId => ({
          id: docs[d].fields[fieldId].id,
          value: docs[d].fields[fieldId].value,
        }));
        const { crew_acknowledged, id } = docs[d];
        const postUrl = `/v2/offers/${offerId}/documents/${id}/save-draft`;
        const promise = ajax.post(
          postUrl,
          { fields, crew_acknowledged },
          { json: true },
        );
        return { promise, documentId: id };
      });

    // If this is an autosave don't reject errors - we don't want to pop up
    // mysterious error messages while the user is typing.
    return processDocumentRequests(documentRequests, !isAutosave);
  };

  _onRejectConfirm = value => {
    const { offerSelectedToReject, i9Verifications: i9Query = {} } = this.state;
    const { offerReject } = this.props;
    if (offerSelectedToReject === null) return;
    offerReject.update('ids')([offerSelectedToReject]);
    offerReject.update('comment')(value);
    offerReject.save('', { json: true }, () => {
      i9Query.reload();
      this._onRejectModalClose();
      this.setState({ offerSelectedToReject: null });
    });
  };
  handleEndDocumentInteraction = () => {
    this.setState({
      displaySigner: false,
      signingOfferId: null,
      errors: [],
    });
  };

  handleRejectI9 = documentId => {
    if (documentId) {
      this.setState({
        rejectModalopen: true,
        documentId: documentId,
      });
    } else {
      this.handleEndDocumentInteraction();
    }
  };

  handleRejectI9Modal = value => {
    if (value) {
      this.setState({
        snackOpen: true,
        message: 'Crew Memeber Notified',
        displaySigner: false,
        signingOfferId: null,
        errors: [],
      });
      const { i9Verifications } = this.props;
      i9Verifications.reload();
    }
    this.setState({
      rejectModalopen: false,
    });
  };

  handleSnackOpen = () => {
    this.setState({
      snackOpen: false,
    });
  };

  filterDocsForI9 = doc => {
    const i9Tags = ['i9', 'i-9'];
    const { categories = [] } = doc;
    for (let i = 0; i < categories.length; i += 1) {
      if (i9Tags.includes(categories[i].name.toLowerCase())) return true;
    }
    return false;
  };
  _onRejectModalClose = () => {
    this.setState({ showRejectModal: false });
  };
  _renderSuccess = () => {
    const {
      displaySigner,
      signingOfferId: offerId,
      showRejectModal,
      errors,
      rejectModalopen,
      snackOpen,
      message,
      documentId,
    } = this.state;
    const {
      i9Verifications: {
        reload: reloadQuery,
        status,
        data: { items: i9Verifications = [] } = {},
      } = {},
      _params: params = {},
      classes = {},
    } = this.props;
    const { projectId } = params;
    const selectedOffer = i9Verifications.find(({ id }) => id === offerId);
    const loading = status === 'loading';

    const user = {
      firstName: selectedOffer?.first_name || '',
      lastName: selectedOffer?.last_name || '',
      email: selectedOffer?.email || '',
    };
    return (
      <React.Fragment>
        <OfferRejectModal
          content={REJECT_MODAL_CONTENT}
          onClose={this._onRejectModalClose}
          onSubmit={this._onRejectConfirm}
          visible={showRejectModal}
        />
        <LayoutContent>
          {displaySigner && (
            <DocumentInteractionController
              buttonText={{
                accept: 'Verify I-9',
                reject: 'Cancel',
              }}
              errors={errors}
              offer={selectedOffer}
              offerId={offerId}
              type="verifier"
              filter={this.filterDocsForI9}
              handleOfferAccept={this.handleOfferAccept}
              handleOfferReject={this.handleRejectI9}
              handleEndDocumentInteraction={this.handleEndDocumentInteraction}
              handleSave={this.handleSave}
            />
          )}
          {!displaySigner && (
            <React.Fragment>
              <Box>
                <BoxItem>
                  <Header>
                    <Header.Title>I-9 Documents Review</Header.Title>
                    <QueryRefresher reloadQuery={reloadQuery} />
                    <Header.Nav>
                      <Search />
                    </Header.Nav>
                  </Header>
                </BoxItem>
                {loading && <Loader className={classes.loader} />}
                {!loading && (
                  <Content
                    items={i9Verifications}
                    endDateAllowed={this.props.project.data.allow_end_date}
                    pastDueLimit={PAST_DUE_LIMIT}
                    projectId={projectId}
                    displayControls={!this.props.me.data.is_support_admin}
                    openSigner={this.openSigner}
                  />
                )}
              </Box>
              {!loading && this._renderPagination()}
            </React.Fragment>
          )}
          {rejectModalopen && (
            <RejectI9
              offerId={offerId}
              documentId={documentId}
              onClose={value => this.handleRejectI9Modal(value)}
              employee={user}
            />
          )}
          <Notification
            message={message}
            open={snackOpen}
            autoHide={2000}
            setOpen={this.handleSnackOpen}
            severity={'success'}
          />
        </LayoutContent>
      </React.Fragment>
    );
  };

  render() {
    const status = this.props.i9Verifications.status;
    if (status === 'failed') {
      return this._renderFailure();
    }
    return this._renderSuccess();
  }
}

export default compose(
  withStyles(styles),
  withPermissionProtection('can_be_i9_verifier'),
  withApi,
)(I9Verifications);
