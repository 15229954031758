import React, { Component } from 'react';
import ajax from 'common/utilities/ajax';
import collection from 'common/oldJavascripts/utils/collection.js';
import T from 'common/oldJavascripts/utils/i18n';
import classNames from 'classnames';
import {
  pushNotification,
  popNotification,
} from 'common/store/actions/snackbarNotifications';
import ContactDetails from 'common/oldJavascripts/components/Base/ContactDetails';
import Dropdown from 'common/oldJavascripts/components/Base/Dropdown';
import LayoutHeader from 'common/oldJavascripts/components/Base/Layout/LayoutHeader';
import GlobalSecondaryNavBar from 'common/oldJavascripts/components/Pages/Layouts/GlobalNavbar/SecondaryNavbar';
import GlobalNavStepper from 'common/oldJavascripts/components/Pages/Layouts/GlobalNavbar/OnboardingStepper';
import MainHeader from 'common/oldJavascripts/components/Base/MainHeader';
import { Typography, Tooltip } from '@mui/material';
import { Lens as LensIcon } from '@mui/icons-material';
import PrintIcon from 'common/images/printer.svg';
import FileIcon from 'common/images/fileDownload.svg';
import { compose } from 'redux';
import { graphql } from 'react-apollo';
import withApi from 'common/hoc/withApi';
import { withStyles } from '@mui/styles';
import { connect } from 'react-redux';
import withDialogNotification from 'common/hoc/withDialogNotification';
import * as Queries from 'common/apollo/queries';
import Feature from 'common/components/Feature';
import truncateStringToFitLength from 'common/utilities/truncateStringToFitLength';

// Context
import { FeatureFlagsContext } from 'common/context';

// Constants
import * as palette from 'common/shared/oldDocumentSignerUI/palette';
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';
import history from 'common/constants/config/history';
import PrintNavOptions from 'onboarding/components/PrintNavOptions';
import { styled } from '@mui/system';

const STEPS = [
  {
    title: T('employee.onboarding.intro.details.title'),
    desc: T('employee.onboarding.intro.details.description'),
    path: /onboarding\/details/,
    introPath: /onboarding\/intro\/details/,
  },
  {
    title: 'Supporting Documents',
    desc: 'Supporting Document Request',
    path: /onboarding\/offers\/\d+\/supporting-documents/,
    introPath: /onboarding\/intro\/supporting-documents/,
  },
  {
    title: T('Start Packet Forms'),
    desc: T('Start Packet Forms'),
    paths: ['documents', 'i9'],
  },
  {
    title: T('employee.onboarding.intro.submit_packet.title'),
    desc: T('employee.onboarding.intro.submit_packet.description'),
    path: /onboarding\/offers\/\d+\/complete/,
    introPath: /onboarding\/intro\/complete/,
  },
];

const styles = {
  navItem: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  navIcon: {
    marginRight: '7.5px',
    color: '#000000',
  },
  firstNavItem: {
    marginRight: '10px',
  },
  dialogConfirmationText: {
    textAlign: 'center',
    fontWeight: 700,
  },
  dialogHeader: {
    textAlign: 'center',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    overflow: 'hidden',
    width: 'max-content',
  },
  projectTitle: {
    marginLeft: '0',
    paddingLeft: '20px',
    fontSize: '1.53846154em !important',
    lineHeight: '3 !important',
    color: '#ffffff',
    fontWeight: '700 !important',
  },
  projectStatus: {
    alignSelf: 'center',
    fontSize: '1.3em',
    fontWeight: '400',
    textTransform: 'uppercase',
  },
  projectStatusLive: {
    color: '#ffffff',
  },
  projectStatusTest: {
    color: '#ffffff57',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px',
    marginTop: '15px',
  },
  statusIcon: {
    height: '12px',
    width: '12px',
  },
  liveIcon: {
    color: palette.green,
  },
  testIcon: {
    color: '#3434348f',
  },
};

const StyledProjectTitle = styled(Typography)({
  marginLeft: '0',
  paddingLeft: '20px',
  fontSize: '1.3em',
  lineHeight: '3',
  color: '#000000',
  fontWeight: '700',
  marginTop: '6px',
});

const StyledContainer = styled('nav')({
  display: 'flex',
  alignItems: 'center',
  right: 10,
});

const StyledStatus = styled(Typography)({
  color: '#000000',
  textTransform: 'capitalize',
  marginLeft: '5px',
});

const Rectangle = styled('div')({
  width: '10px',
  height: '35px',
  backgroundColor: '#D0D5DD',
  display: 'inline-block',
  marginLeft: '20px',
  borderRadius: '6px',
});

class HeaderOfferOnboarding extends Component {
  static contextType = FeatureFlagsContext;

  static mutators = {
    offer: {
      info: function(_, related) {
        var params = related['/router/params'];

        return {
          id: '/v2/offers/' + params.offerId,
        };
      },
    },
  };

  static queries = {
    hirer: {
      info: function(_, related) {
        var params = related['/router/params'];
        return {
          id: '/v2/offers/' + params.offerId + '/hirer',
        };
      },
    },
    documents: {
      info: function(_, related) {
        const { offerId } = related['/router/params'];
        return {
          id: `/v2/offers/${offerId}/documents-details`,
        };
      },
    },
  };

  getPrintableForm = () => {
    const {
      offer: offerQuery = {},
      pushNotification,
      popNotification,
    } = this.props;
    const { data: { id: offerId } = {} } = offerQuery || {};
    pushNotification({
      message:
        'Hold on a moment while we prepare the document for download. Your download should being momentarily.',
      variant: SnackbarVariants.INFO,
    });
    ajax
      .get(`/v2/offers/${offerId}/paper-document`)
      .then(res => {
        const { path } = res || {};
        if (path) {
          popNotification();
          pushNotification({
            message: 'Your download has started.',
            variant: SnackbarVariants.SUCCESS,
          });
          window.location.href = path;
        } else {
          throw new Error(
            'No path received from api when downloading offer documents',
          );
        }
      })
      .catch(err => {
        const { resource: { errors = {} } = {} } = err;
        const { url: [apiErrorMessage] = [] } = errors || {};
        const message = apiErrorMessage
          ? apiErrorMessage
          : 'There was an error while trying to download the document. Please try again or contact customer support if the issue persists.';
        pushNotification({
          message,
          variant: SnackbarVariants.ERROR,
        });
      });
  };

  dismissContinueOnPaperDialog = () => {
    const { popDialogNotification } = this.props;
    popDialogNotification();
  };

  continueOnboardingOnPaper = () => {
    const {
      routerParams: { offerId } = {},
      pushNotification,
      popNotification,
    } = this.props;
    pushNotification({
      message:
        'Hold on a moment while we opt you out of digital onboarding and prepare the document for download. Your download should being momentarily.',
      variant: SnackbarVariants.INFO,
    });
    ajax
      .post(`/v2/offers/${offerId}/opt-out`)
      .then(({ path = '' }) => {
        if (path) {
          popNotification();
          pushNotification({
            message: 'Your download has started.',
            variant: SnackbarVariants.SUCCESS,
          });
          window.location.href = path;
          history.push('/onboarding/offers');
        } else {
          throw new Error(
            'No path received from api when downloading offer documents',
          );
        }
      })
      .catch(err => {
        pushNotification({
          message:
            'Please contact your production office or support (plus.support@castandcrew.com or (818) 848-0999) if you need assistance.',
          variant: SnackbarVariants.ERROR,
        });
      });
  };

  oldContinueOnPaper = () => {
    this.dismissContinueOnPaperDialog();
    this.continueOnboardingOnPaper();
  };

  render() {
    const {
      routerPath: path,
      hirer: hirerQuery = {},
      offer: offerQuery = {},
      data: { project = {} } = {},
      documents: documentsQuery = {},
      pushDialogNotification,
      classes = {},
    } = this.props;
    const { data: hirer = {} } = hirerQuery || {};
    const { data: offer = {} } = offerQuery || {};
    const { status: offerStatus = '' } = offer;
    const { items: documentsData = [] } = documentsQuery.data;
    const supportingDocumentTitlesList = [];

    documentsData.forEach(doc => {
      const { supportingDocumentTitles = [] } = doc;
      if (supportingDocumentTitles.length > 0) {
        supportingDocumentTitlesList.push(supportingDocumentTitles);
      }
    });

    const hasSupportingDocuments = supportingDocumentTitlesList.length > 0;
    const onboardingSteps = hasSupportingDocuments
      ? STEPS
      : STEPS.filter(key => key.title !== 'Supporting Documents');

    let contactHiringManager = null;

    const { status, name } = project || {};

    const statusClass = classNames(classes.projectStatus, {
      [classes.projectStatusLive]: status === 'live',
      [classes.projectStatusTest]: status === 'test',
    });

    const iconClass = classNames(classes.statusIcon, {
      [classes.liveIcon]: status === 'live',
      [classes.testIcon]: status === 'test',
    });
    let currentStep = null;
    // Find active step
    collection.each(onboardingSteps, function(index, item) {
      const { path: itemPath = null, paths = [], introPath } = item;
      if (paths.length > 0) {
        const str = path.split('/');
        const query = str[str.length - 1];
        if (paths.indexOf(query) !== -1) {
          currentStep = index + 1;
          return false;
        }
      }
      if (
        (itemPath && path.match(itemPath)) ||
        (introPath && path.match(introPath))
      ) {
        currentStep = index + 1;
        return false;
      }
    });

    if (hirerQuery.status !== 'loading') {
      contactHiringManager = (
        <Dropdown
          title={T('dashboard.header.contact_hiring_manager')}
          header
          hideAvatar
          secondary
        >
          <Dropdown.Item>
            <ContactDetails.Prefab
              detailName={hirer.first_name + ' ' + hirer.last_name}
              detailTitle={hirer.title}
              detailEmail={hirer.email}
              detailPhone={hirer.phone}
            />
          </Dropdown.Item>
        </Dropdown>
      );
    }

    const dialogProps = {
      title: (
        <Typography variant="h4" className={classes.dialogHeader}>
          Heads up!
        </Typography>
      ),
      message: [
        <React.Fragment>
          <Typography variant="subtitle1">
            By clicking "Continue on paper" you are opting not to complete your
            offer using digital on-board and you will now be required to
            complete and sign your offer on paper and submit your documents in
            person.
          </Typography>
          <Typography
            className={classes.dialogConfirmationText}
            variant="subtitle1"
          >
            Are you sure you want to continue?
            <br />
            (You can print the documents to complete and sign on paper)
          </Typography>
        </React.Fragment>,
      ],
      actions: [
        {
          text: 'No, take me back',
          fn: this.dismissContinueOnPaperDialog,
          color: 'secondary',
        },
        {
          text: 'PRINT DOCUMENTS and CONTINUE ON PAPER',
          fn: this.oldContinueOnPaper,
          color: 'primary',
        },
      ],
    };

    const OldPrintOptionsNavItems = () => {
      if (offerStatus === 'sent' || offerStatus.includes('onboarding')) {
        return (
          <React.Fragment>
            <Tooltip
              disableInteractive
              title="By selecting print you have not opted out of digital on-boarding. These documents are only for review."
              data-test-id={'HeaderOfferOnboarding-printDocumentsTooltip'}
            >
              <div
                className={classNames(classes.navItem, classes.firstNavItem)}
                onClick={this.getPrintableForm}
                data-test-id="HeaderOfferOnboarding-printDocuments"
              >
                <img src={PrintIcon} alt="Print Icon" />
              </div>
            </Tooltip>
            <Tooltip
              disableInteractive
              title="Continue on Paper – by selecting this option you will opt out of the digital onboarding and will be required to complete and sign your offer on paper and submit your documents in person."
              data-test-id={'HeaderOfferOnboarding-optOutTooltip'}
            >
              <div
                className={classes.navItem}
                onClick={() => pushDialogNotification(dialogProps)}
                data-test-id="HeaderOfferOnboarding-optOut"
              >
                <img src={FileIcon} alt="File Icon" />
              </div>
            </Tooltip>
          </React.Fragment>
        );
      } else {
        return null;
      }
    };
    const statusLoaded = name && status;
    const flags = this.context || [];
    const globalNavDefault =
      process.env.REACT_APP_ENABLE_GLOBAL_NAV_BY_DEFAULT === 'true';
    const globalNavFlag = flags.includes('GlobalNavbar') || globalNavDefault;
    const renderProjectName = () => {
      if (globalNavFlag) {
        return (
          <div className={classes.titleContainer}>
            <Rectangle />
            <StyledProjectTitle>
              {truncateStringToFitLength(18, name)}
            </StyledProjectTitle>{' '}
            <div className={classes.statusContainer}>
              <LensIcon className={iconClass} />
              <StyledStatus>{status}</StyledStatus>
            </div>
          </div>
        );
      }

      return (
        <div className={classes.titleContainer}>
          <Typography className={classes.projectTitle}>{name}</Typography>{' '}
          <div className={classes.statusContainer}>
            <LensIcon className={iconClass} />
            <Typography className={statusClass}>{status}</Typography>
          </div>
        </div>
      );
    };

    const HeaderComponent = globalNavFlag ? GlobalSecondaryNavBar : MainHeader;
    const StepperComponent = globalNavFlag
      ? GlobalNavStepper
      : MainHeader.Progress;
    const NavItem = globalNavFlag ? StyledContainer : MainHeader.Nav;

    return (
      <LayoutHeader globalNav={globalNavFlag}>
        <HeaderComponent secondary={true}>
          {statusLoaded && renderProjectName()}
          <StepperComponent currentStep={currentStep} steps={onboardingSteps} />
          <NavItem secondary={true} flex={true}>
            <Feature
              name="mobileOnboarding"
              fallback={<OldPrintOptionsNavItems />}
            >
              <PrintNavOptions
                globalNavFlag={globalNavFlag}
                continueOnPaper={this.continueOnboardingOnPaper}
                getPrintableForm={this.getPrintableForm}
              />
            </Feature>
            {!globalNavFlag && contactHiringManager}
          </NavItem>
        </HeaderComponent>
      </LayoutHeader>
    );
  }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  pushNotification,
  popNotification,
};

const projectStatusConfig = {
  skip: ({ offer: { data: { project_id: projectId } = {} } = {} } = {}) =>
    !projectId,
  options: ({ offer: { data: { project_id: projectId } = {} } = {} } = {}) => ({
    variables: { id: parseInt(projectId, 10) },
  }),
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withApi,
  graphql(Queries.GetProjectNameStatus, projectStatusConfig),
  withDialogNotification,
)(HeaderOfferOnboarding);
