import React from 'react';
import { withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { compose } from 'redux';
import withApi from 'common/hoc/withApi';
import useFeatureFlags from 'common/hooks/useFeatureFlags';
import HamburgerMenu from './HamburgerMenu';
import HamburgerMenuOld from './HamburgerMenu_OLD';
import { styled } from '@mui/system';
import truncateStringToFitLength from 'common/utilities/truncateStringToFitLength.js';

const styles = {
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    overflow: 'hidden',
    backgroundColor: '#ffffff',
    width: '100%',
  },
  projectTitle: {
    textAlign: 'center',
    position: 'relative',
    top: 5,
  },
};

const StyledProjectTitle = styled(Typography)({
  marginLeft: '0',
  paddingLeft: '20px',
  fontSize: '1.3em',
  lineHeight: '3',
  color: '#182230',
  fontWeight: '700',
  marginTop: '6px',
});

const Rectangle = styled('div')({
  width: '10px',
  height: '35px',
  backgroundColor: '#D0D5DD',
  display: 'inline-block',
  marginLeft: '20px',
  borderRadius: '6px',
});

const ProjectHamburgerMenu = ({ classes, project: projectQuery }) => {
  const { name: projectName = '', id: projectId } = projectQuery.data || {};

  const flags = useFeatureFlags();
  const globalNavDefault =
    process.env.REACT_APP_ENABLE_GLOBAL_NAV_BY_DEFAULT === 'true';
  const globalNavFlag = flags.includes('GlobalNavbar') || globalNavDefault;
  const HamburgerMenuComponent = globalNavFlag
    ? HamburgerMenu
    : HamburgerMenuOld;
  return (
    <HamburgerMenuComponent
      nonEmployee={true}
      projectTitle={projectName}
      projectId={projectId}
    >
      {globalNavFlag && projectName && (
        <div className={classes.titleContainer}>
          <Rectangle />
          <StyledProjectTitle data-test-id="HamburgerMenu-projectTitle">
            {truncateStringToFitLength(29, projectName)}
          </StyledProjectTitle>
        </div>
      )}
      {!globalNavFlag && (
        <Typography
          data-test-id="HamburgerMenu-projectTitle"
          className={classes.projectTitle}
        >
          {projectName}
        </Typography>
      )}
    </HamburgerMenuComponent>
  );
};

ProjectHamburgerMenu.queries = {
  project: {
    info: (_, related) => {
      const { projectId } = related['/router/params'];
      return {
        id: projectId ? '/projects/' + projectId : 'empty',
      };
    },
  },
};

export default compose(withApi, withStyles(styles))(ProjectHamburgerMenu);
