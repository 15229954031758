import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { ALLOWANCES } from 'mobile/constants';
import { createOfferCurrency as currency } from 'common/oldJavascripts/utils/format';
import useFeatureFlags from 'common/hooks/useFeatureFlags';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: '60px',
  },
  noAllowance: {
    fontSize: '18px !important',
    color: '#999999 !important',
    textAlign: 'center',
  },
  title: {
    color: '#646464 !important',
    fontSize: '24px !important',
    fontWeight: 400,
    marginBottom: '18px !important',
  },
  name: {
    color: '#5b5b5b !important',
    fontSize: '16px !important',
    fontWeight: '500 !important',
  },
  perDay: {
    color: '#0000FF !important',
    fontSize: '12px !important',
    fontWeight: 400,
    position: 'relative',
    bottom: '9px',
  },
  capTitle: {
    color: '#5b5b5b !important',
    fontSize: '12px !important',
    fontWeight: '500 !important',
  },
  smallCapAmount: {
    color: '#5b5b5b',
    fontSize: '12px',
    fontWeight: 700,
  },
  amount: {
    color: '#0000FF',
    fontSize: '30px !important',
    fontWeight: '400 !important',
    wordWrap: 'break-word',
  },
  allowances: {
    backgroundColor: '#E2F1FF !important',
    padding: 20,
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: '10px',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
    '& div': {
      padding: 5,
      [theme.breakpoints.down('md')]: {
        marginBottom: '20px',
      },
    },
  },
}));

const formatAmount = amount => {
  return !Number.isInteger(amount) ? currency(amount) : `$${amount}`;
};

const Allowances = props => {
  const classes = useStyles();
  const flags = useFeatureFlags();
  const isDurationFieldEnabled = flags?.includes('AllowanceDurationField');
  const { terms_of_employment: terms = [] } = props?.offerData;
  const isEmptyAllowance = ALLOWANCES.every(
    ({ key }) => !terms[`${key}_amount`],
  );

  if (isEmptyAllowance) return null;

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>Allowances</Typography>
      <div className={classes.allowances}>
        {ALLOWANCES.map(({ key, title }) => {
          const amount = terms[key + '_amount'];
          const perDay = terms[key + '_per'];
          const duration = terms[key + '_duration'] || 'Run of Show';
          const cap = terms[key + '_cap'];
          if (!amount) return null;
          return (
            <div>
              <Typography className={classes.name}>{title}</Typography>
              <Typography className={classes.amount}>
                {formatAmount(amount)}
              </Typography>
              <Typography className={classes.perDay}>per {perDay}</Typography>

              <Typography className={classes.capTitle}>
                Cap: {formatAmount(cap)}
              </Typography>
              {!isDurationFieldEnabled && (
                <Typography className={classes.capTitle}>
                  Duration:{' '}
                  <span className={classes.smallCapAmount}>{duration}</span>
                </Typography>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default Allowances;
